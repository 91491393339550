
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image } from "vant";
import { Swiper } from "swiper";

@Component({
  components: {
    [Image.name]: Image,
  },
})
export default class productLayout3 extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public keyword!: string[];
  @Prop({
    type: String,
    default: "",
  })
  public id!: string;
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public projectList!: Array<cms.SubjectProductsDto>;
  @Watch("projectList")
  changeList() {
    console.log(2);
    this.initSwiper();
  }
  public mySwiper: any = null;

  mounted() {
    if (this.projectList.length) {
      console.log(1, this.projectList);
      this.initSwiper();
    }
  }

  initSwiper() {
    if (this.mySwiper) {
      this.$nextTick(() => {
        this.mySwiper.update(true);
      });
      return;
    }
    this.mySwiper = new Swiper("#swiper" + this.id, {
      slidesPerView: "auto",
    });
    console.log("mySwiper", this.mySwiper);
  }
  goProDetail(item: cms.SubjectProductsDto) {
    this.$emit("goProDetail", item);
  }

  remind(item: cms.SubjectProductsDto) {
    this.$emit("remind", item);
  }
}
