
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image } from "vant";

@Component({
  components: {
    [Image.name]: Image,
  },
})
export default class productLayout2 extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public keyword!: string[];
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public projectList!: Array<cms.SubjectProductsDto>;

  goProDetail(item: cms.SubjectProductsDto) {
    this.$emit("goProDetail", item);
  }

  remind(item: cms.SubjectProductsDto) {
    this.$emit("remind", item);
  }
}
