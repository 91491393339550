
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";

@Component({})
export default class CouponLayout4 extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public couponList!: cms.SubjectCouponInfoDto[];

  receive(item: cms.SubjectCouponInfoDto) {
    if (item.alreadyReceiveQuantity === item.quantity) {
      this.$toast("优惠券已领光");
      return;
    }
    this.$emit("receive", item);
  }
}
