var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"SpecialTheme",style:({ backgroundColor: _vm.subjectInfo.specialColor })},_vm._l((_vm.layoutList),function(item,index){return _c('div',{key:index,class:`special-item type-${item.layoutType}-${item.layoutStyle}`},[(item.layoutType === '01')?_vm._l((item.subjectImages),function(image,idx1){return _c('van-image',{key:idx1,attrs:{"src":image.imgUrl,"width":item.layoutStyle === '02' ? '50%' : '100%'},on:{"click":function($event){return _vm.goto(image)}}})}):(
        item.layoutType === '02' &&
        (item.navigation ||
          _vm.productMap[item.subjectLayoutId].productList.length)
      )?[(item.titleImg)?_c('van-image',{attrs:{"src":item.titleImg}}):_vm._e(),_c('div',{ref:`container${item.subjectLayoutId}`,refInFor:true},[(item.navigation)?_c('van-sticky',{attrs:{"container":_vm.containerMap[item.subjectLayoutId],"offset-top":"-.3rem"}},[_c('div',{class:{
              'nav-wrap': _vm.productMap[item.subjectLayoutId].navList.length > 5,
            }},[_c('van-tabs',{style:({ backgroundColor: _vm.subjectInfo.specialColor }),attrs:{"color":"#fff","background":"rgba(0, 0, 0, 0.30)","title-active-color":"#fff","title-inactive-color":"#fff"},on:{"click":function($event){return _vm.changeTab(item.subjectLayoutId)}},model:{value:(_vm.productMap[item.subjectLayoutId].navigationId),callback:function ($$v) {_vm.$set(_vm.productMap[item.subjectLayoutId], "navigationId", $$v)},expression:"productMap[item.subjectLayoutId].navigationId"}},_vm._l((_vm.productMap[item.subjectLayoutId]
                  .navList),function(nav,idx2){return _c('van-tab',{key:idx2,attrs:{"name":nav.navigationId,"title":nav.navigationName}})}),1),_c('van-popover',{attrs:{"trigger":"click","placement":"bottom-end"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('van-image',{attrs:{"width":".4rem","height":".66rem","src":require('@/assets/images/SpecialTheme/shadow.png')}}),_c('van-button',{staticClass:"more-btn",attrs:{"type":"primary"}},[_vm._v("更多")])]},proxy:true}],null,true),model:{value:(_vm.productMap[item.subjectLayoutId].showMore),callback:function ($$v) {_vm.$set(_vm.productMap[item.subjectLayoutId], "showMore", $$v)},expression:"productMap[item.subjectLayoutId].showMore"}},[_c('div',{staticClass:"more-box"},_vm._l((_vm.productMap[item.subjectLayoutId]
                    .navList),function(nav,idx2){return _c('span',{key:idx2,staticClass:"more-item",class:{
                    active:
                      _vm.productMap[item.subjectLayoutId].navigationId ===
                      nav.navigationId,
                  },on:{"click":function($event){return _vm.changeMoreTab(nav.navigationId, item.subjectLayoutId)}}},[_vm._v(_vm._s(nav.navigationName))])}),0)])],1)]):_vm._e(),_c('van-list',{attrs:{"finished":_vm.productMap[item.subjectLayoutId].finished},on:{"load":function($event){return _vm.onLoadProduct(item.subjectLayoutId)}},model:{value:(_vm.productMap[item.subjectLayoutId].loading),callback:function ($$v) {_vm.$set(_vm.productMap[item.subjectLayoutId], "loading", $$v)},expression:"productMap[item.subjectLayoutId].loading"}},[(_vm.productMap[item.subjectLayoutId].productList.length === 0)?_c('div',{staticClass:"no-data"},[_c('van-image',{staticClass:"no-data-img",attrs:{"src":require('@/assets/images/SpecialTheme/no-product.png')}})],1):[(item.layoutStyle === '01')?_c('productLayout1',{attrs:{"project-list":_vm.productMap[item.subjectLayoutId].productList},on:{"goProDetail":_vm.gotoProduct,"remind":_vm.remindProduct}}):(item.layoutStyle === '02')?_c('productLayout2',{attrs:{"project-list":_vm.productMap[item.subjectLayoutId].productList},on:{"goProDetail":_vm.gotoProduct,"remind":_vm.remindProduct}}):(item.layoutStyle === '03')?_c('productLayout3',{attrs:{"id":item.subjectLayoutId,"project-list":_vm.productMap[item.subjectLayoutId].productList},on:{"goProDetail":_vm.gotoProduct,"remind":_vm.remindProduct}}):(item.layoutStyle === '04')?_c('productLayout4',{attrs:{"project-list":_vm.productMap[item.subjectLayoutId].productList},on:{"goProDetail":_vm.gotoProduct,"remind":_vm.remindProduct}}):_vm._e()]],2)],1)]:(
        item.layoutType === '03' &&
        _vm.goodsMap[item.subjectLayoutId].goodsList.length
      )?[(item.titleImg)?_c('van-image',{attrs:{"src":item.titleImg}}):_vm._e(),_c('van-list',{staticClass:"goods-list",attrs:{"finished":_vm.goodsMap[item.subjectLayoutId].finished},on:{"load":function($event){return _vm.onLoadGoods(item.subjectLayoutId)}},model:{value:(_vm.goodsMap[item.subjectLayoutId].loading),callback:function ($$v) {_vm.$set(_vm.goodsMap[item.subjectLayoutId], "loading", $$v)},expression:"goodsMap[item.subjectLayoutId].loading"}},[(_vm.goodsMap[item.subjectLayoutId].goodsList.length == 0)?_c('div',{staticClass:"no-data"},[_c('van-image',{staticClass:"no-data-img",attrs:{"src":require('@/assets/images/SpecialTheme/no-goods.png')}})],1):[(item.layoutStyle === '01')?_c('mallProList',{attrs:{"mall-recommend-list":_vm.goodsMap[item.subjectLayoutId].goodsList}}):(item.layoutStyle === '02')?_c('WaterfallLayout',{attrs:{"classify-list":_vm.goodsMap[item.subjectLayoutId].goodsList},on:{"goMallDetails":_vm.gotoMall}}):_vm._e()]],2)]:(
        item.layoutType === '04' &&
        item.subjectCoupons &&
        item.subjectCoupons.length
      )?[(item.titleImg)?_c('van-image',{attrs:{"src":item.titleImg}}):_vm._e(),(item.subjectCoupons && item.subjectCoupons.length === 0)?_c('div',{staticClass:"no-data"},[_c('van-image',{staticClass:"no-data-img no-coupon",attrs:{"src":require('@/assets/images/SpecialTheme/no-coupon.png')}})],1):[(item.layoutStyle === '01')?_c('couponLayout1',{attrs:{"coupon-list":item.subjectCoupons},on:{"receive":_vm.receiveCoupon}}):(item.layoutStyle === '02')?_c('couponLayout2',{attrs:{"coupon-list":item.subjectCoupons},on:{"receive":_vm.receiveCoupon}}):(item.layoutStyle === '03')?_c('couponLayout3',{attrs:{"coupon-list":item.subjectCoupons},on:{"receive":_vm.receiveCoupon}}):(item.layoutStyle === '04')?_c('couponLayout4',{attrs:{"coupon-list":item.subjectCoupons},on:{"receive":_vm.receiveCoupon}}):_vm._e()]]:_vm._e()],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }